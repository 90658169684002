import * as React from "react"
import BasicPage from "../components/commons/basic-page"
import Layout from "../components/layout/es"

const PoliticaDeCookie = (props, Content) => {

  return (
    <Layout robots="block" pageId="cookies" location={props.location} metaTitle="Política de cookies | Crequs">
        <BasicPage 
            title="Política de cookies">
            <p><strong>INTRODUCCI&Oacute;N</strong></p>
            <p>La web de Crequs utiliza cookies propias y de terceros. Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas p&aacute;ginas web, entre otras finalidades, asegurar el correcto funcionamiento de la p&aacute;gina, permitir al Usuario un acceso m&aacute;s r&aacute;pido a los servicios seleccionados, almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo e incluso, dependiendo de la informaci&oacute;n que contengan y de la forma en que utilice su equipo, se pueden utilizar para reconocer al usuario. Las cookies se asocian &uacute;nicamente a un usuario an&oacute;nimo y su ordenador o dispositivo y no proporcionan referencias que permitan conocer sus datos personales., salvo permiso expreso de aqu&eacute;l.</p>
            <p>El usuario puede, en todo momento, aceptar o rechazar las cookies instaladas que no sean estrictamente necesarias para el correcto funcionamiento de la web y el acceso al Usuario a sus servicios, a trav&eacute;s del panel de ajuste de cookies proporcionado en nuestra web. Asimismo podr&aacute; configurar su navegador en todo momento sin que ello perjudique la posibilidad del Usuario de acceder a los contenidos. Sin embargo le informamos de que el rechazo de las cookies puede disminuir el buen funcionamiento de la web.</p>
            <h2>COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXENTAS</h2>
            <p>Seg&uacute;n la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de anal&iacute;tica,&nbsp; las de publicidad y afiliaci&oacute;n, quedando exceptuadas las de car&aacute;cter t&eacute;cnico y las necesarias para el funcionamiento del sitio web o la prestaci&oacute;n de servicios expresamente demandados por el usuario.</p>
            <h2>TIPOS DE COOKIES</h2>
            <ol>
            <li>a) Seg&uacute;n la&nbsp;<strong>entidad que las gestione</strong>, hay Cookies&nbsp;<strong>propias</strong>&nbsp;(aquellas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio Solicitado por el usuario) y de&nbsp;<strong>terceros</strong>&nbsp;((las que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos mediante las galletas).</li>
            <li>b) Seg&uacute;n el&nbsp;<strong>tiempo que permanecen activas</strong>, existen las de&nbsp;<strong>sesi&oacute;n</strong>&nbsp;(dise&ntilde;adas para recabar y almacenar datos mientras el usuario accede a una p&aacute;gina web) y las&nbsp;<strong>persistentes</strong>&nbsp;(en las que los datos se almacenan en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie -puede ir de unos minutos a varios a&ntilde;os-).</li>
            <li>c) Seg&uacute;n la&nbsp;<strong>finalidad</strong>&nbsp;para la que traten la informaci&oacute;n que recopilan, pueden ser:</li>
            </ol>
            <ul>
            <li><strong>Cookies t&eacute;cnicas</strong>&nbsp;(necesarias para el uso de la web y la prestaci&oacute;n del servicio contratado),</li>
            <li><strong>Cookies de preferencias</strong>&nbsp;(que permiten al usuario acceder al servicio con caracter&iacute;sticas predefinidas, como por ejemplo el idioma, tipo de navegador, configuraci&oacute;n regional, etc.)</li>
            <li><strong>Cookies de an&aacute;lisis</strong>&nbsp;(recogen informaci&oacute;n el uso que se realiza de la web),</li>
            <li><strong>Cookies publicitarias</strong>&nbsp;(recogen informaci&oacute;n sobre las preferencias y elecciones personales de los usuarios),</li>
            <li><strong>Cookies de afiliados</strong>&nbsp;(permiten realizar un seguimiento de las visitas procedentes de otras webs, con las que el sitio web establece un contrato de afiliaci&oacute;n).</li>
            </ul>
            <h2>TIPOS DE COOKIES UTILIZADAS POR ESTE SITIO WEB</h2>
            <table className="cookielawinfo-row-cat-table cookielawinfo-winter">
            <thead>
            <tr>
            <th className="cookielawinfo-column-1">Cookie</th>
            <th className="cookielawinfo-column-3">Duraci&oacute;n</th>
            <th className="cookielawinfo-column-4">Descripci&oacute;n</th>
            </tr>
            </thead>
            <tbody>
            <tr className="cookielawinfo-row">
            <td className="cookielawinfo-column-1">_ga</td>
            <td className="cookielawinfo-column-3">2 a&ntilde;os</td>
            <td className="cookielawinfo-column-4">Esta cookie es instalada por Google Analytics. La cookie se utiliza para calcular los datos de visitantes, sesiones, camapign y realizar un seguimiento del uso del sitio para el informe anal&iacute;tico del sitio. Las cookies almacenan informaci&oacute;n de forma an&oacute;nima y asignan un n&uacute;mero generado aleatoriamente para identificar visitantes &uacute;nicos.</td>
            </tr>
            <tr className="cookielawinfo-row">
            <td className="cookielawinfo-column-1">_dc_gtm_UA-66887712-1</td>
            <td className="cookielawinfo-column-3">2 years</td>
            <td className="cookielawinfo-column-4">Esta cookie es instalada por Google Analytics. La cookie se utiliza para calcular los datos de visitantes, sesiones, camapign y realizar un seguimiento del uso del sitio para el informe anal&iacute;tico del sitio. Las cookies almacenan informaci&oacute;n de forma an&oacute;nima y asignan un n&uacute;mero generado aleatoriamente para identificar visitantes &uacute;nicos.</td>
            </tr>
            <tr className="cookielawinfo-row">
            <td className="cookielawinfo-column-1">_gid</td>
            <td className="cookielawinfo-column-3">1 d&iacute;a</td>
            <td className="cookielawinfo-column-4">Esta cookie es instalada por Google Analytics. La cookie se utiliza para almacenar informaci&oacute;n sobre c&oacute;mo los visitantes usan un sitio web y ayuda a crear un informe anal&iacute;tico de c&oacute;mo est&aacute; funcionando el sitio web. Los datos recopilados, incluidos el n&uacute;mero de visitantes, la fuente de donde provienen y las p&aacute;ginas, aparecieron de forma an&oacute;nima.</td>
            </tr>
            </tbody>
            </table>
            <p>&nbsp;</p>
            <h2>REVOCACI&Oacute;N</h2>
            <p>En todo momento podr&aacute; acceder a la configuraci&oacute;n de su navegador aceptando o rechazando todas las cookies, o bien seleccionar aqu&eacute;llas cuya instalaci&oacute;n admite y cu&aacute;les no, siguiendo uno de los siguientes procedimientos, que depende del navegador que utilice:</p>
            <p><strong>Google Chrome</strong>&nbsp;(en el Men&uacute; Herramientas):</p>
            <p>Configuraci&oacute;n &gt; Mostrar opciones avanzadas &gt; Privacidad (Configuraci&oacute;n de contenido) &gt; Cookies: https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktopandhl=es</p>
            <p><strong>Microsoft Internet Explorer</strong>&nbsp;(en el Men&uacute; Herramientas):</p>
            <p>Opciones de Internet &gt; Privacidad &gt; Avanzada: https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</p>
            <p><strong>Firefox:</strong></p>
            <p>Opciones &gt; Privacidad &gt; Cookies: https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</p>
            <p><strong>Safari, iPad y iPhone:</strong></p>
            <p>Preferencias &gt; Privacidad: https://support.apple.com/kb/ph21411?locale=es_ES</p>
            <p><strong>Opera:</strong></p>
            <p>Configuraci&oacute;n &gt; Opciones &gt; Avanzado &gt; Cookies: http://help.opera.com/Windows/12.00/es-ES/cookies.html</p>
            <p>Estos navegadores est&aacute;n sometidos a actualizaciones o modificaciones, por lo que no podemos garantizar que se ajusten completamente a la versi&oacute;n de su navegador. Tambi&eacute;n puede ser que utilice otro navegador no contemplado en estos enlaces como Konqueror, Arora, Flock, etc. Para evitar estos desajustes, puede acceder directamente desde las opciones de su navegador, generalmente en el men&uacute; de &laquo;Opciones&raquo; en la secci&oacute;n de &laquo;Privacidad&raquo;. (Por favor, consulte la ayuda de su navegador para m&aacute;s informaci&oacute;n).</p>
            <h2>DESACTIVACI&Oacute;N/ACTIVACI&Oacute;N Y ELIMINACI&Oacute;N DE COOKIES</h2>
            <p>Para restringir o bloquear las cookies, se hace a trav&eacute;s de la configuraci&oacute;n del navegador.</p>
            <p>Si no desea que los sitios web pongan ninguna cookie en su equipo, puede adaptar la configuraci&oacute;n del navegador de modo que se le notifique antes de que se coloque ninguna cookie. De igual modo, puede adaptar la configuraci&oacute;n de forma que el navegador rechace todas las cookies, o &uacute;nicamente las cookies de terceros. Tambi&eacute;n puede eliminar cualquiera de las cookies que ya se encuentren en el equipo. Tenga en cuenta que tendr&aacute; que adaptar por separado la configuraci&oacute;n de cada navegador y equipo que utilice.</p>
            <p>Tenga en cuenta que si no desea recibir cookies, ya no podremos garantizar que nuestro sitio web funcione debidamente. Puede que algunas funciones del sitio se pierdan y es posible que ya no pueda ver ciertos sitios web. Adem&aacute;s, rechazar las cookies no significa que ya no vaya a ver anuncios publicitarios. Simplemente los anuncios no se ajustar&aacute;n a sus intereses y se repetir&aacute;n con m&aacute;s frecuencia.</p>
            <p>Cada navegador posee un m&eacute;todo distinto para adaptar la configuraci&oacute;n. Si fuera necesario, consulte la funci&oacute;n de ayuda del navegador para establecer la configuraci&oacute;n correcta.</p>
            <p>Para desactivar las cookies en el tel&eacute;fono m&oacute;vil, consulte el manual del dispositivo para obtener m&aacute;s informaci&oacute;n.</p>
            <p>Puede obtener m&aacute;s informaci&oacute;n sobre las cookies en Internet, http://www.aboutcookies.org/.</p>
            <p>Teniendo en cuenta la forma en la que funciona Internet y los sitios web, no siempre contamos con informaci&oacute;n de las cookies que colocan terceras partes a trav&eacute;s de nuestro sitio web. Esto se aplica especialmente a casos en los que nuestra p&aacute;gina web contiene lo que se denominan elementos integrados: textos, documentos, im&aacute;genes o breves pel&iacute;culas que se almacenan en otra parte, pero se muestran en nuestro sitio web o a trav&eacute;s del mismo.</p>
            <p>Por consiguiente, en caso de que se encuentre con este tipo de cookies en este sitio web y no est&eacute;n enumeradas en la lista anterior, le rogamos que nos lo comunique. O bien p&oacute;ngase en contacto directamente con el tercero para pedirle informaci&oacute;n sobre las cookies que coloca, la finalidad y la duraci&oacute;n de la cookie, y c&oacute;mo ha garantizado su privacidad.</p>
            <p>&nbsp;INFORMACI&Oacute;N RELACIONADA CON EL TRATAMIENTO DE DATOS PERSONALES (ART.13 RGPD)</p>
            <p><strong>&iquest;Qui&eacute;n es el responsable del tratamiento de sus datos personales?</strong></p>
            <p>Paolo Bergamelli el responsable del tratamiento de los datos personales que usted nos proporciona, sito en Calle Rollo 5, 28005, Madrid y con CIF Y2729791G&nbsp; Tel&eacute;fono 685337685 y mail de contacto info@crequs.com.</p>
            <p>Si no desea que los sitios web pongan ninguna cookie en su equipo, puede adaptar la configuraci&oacute;n del navegador de modo que se le notifique antes de que se coloque ninguna cookie. De igual modo, puede adaptar la configuraci&oacute;n de forma que el navegador rechace todas las cookies, o &uacute;nicamente las cookies de terceros. Tambi&eacute;n puede eliminar cualquiera de las cookies que ya se encuentren en el equipo. Tenga en cuenta que tendr&aacute; que adaptar por separado la configuraci&oacute;n de cada navegador y equipo que utilice.&nbsp;</p>
            <p><strong>&iquest;Para qu&eacute; utilizamos sus datos personales?</strong></p>
            <p>La informaci&oacute;n recopilada a trav&eacute;s de las cookies propias (cookies de sesi&oacute;n y de entrada de usuario) se suelen utilizar para rastrear las acciones del usuario al rellenar los formularios en l&iacute;nea en varias p&aacute;ginas, o como cesta de la compra para hacer el seguimiento de los art&iacute;culos que el usuario ha seleccionado al pulsar un bot&oacute;n, o para personalizar la interface del usuario al visitar la p&aacute;gina web.</p>
            <p>La informaci&oacute;n recopilada a trav&eacute;s de las cookies de terceros es la siguiente:</p>
            <ul>
            <li>A trav&eacute;s de las cookies anal&iacute;ticas se analizan sus h&aacute;bitos de navegaci&oacute;n para mejorar los servicios prestados;</li>
            <li>A trav&eacute;s de las cookies comportamentales se recoge informaci&oacute;n sobre las preferencias del usuario para gestionar los espacios publicitarios de la forma m&aacute;s eficaz posible; y</li>
            <li>A trav&eacute;s de las cookies publicitarias se mide el rendimiento de los anuncios y se proporcionan recomendaciones relativas a productos basadas en datos estad&iacute;sticos.</li>
            </ul>
            <p>Para ver exactamente el uso de las cookies v&eacute;ase el cuadro sobre el uso de las cookies en esta web.&nbsp;</p>
            <p><strong>&iquest;Cu&aacute;l es la base legal para el tratamiento de sus datos personales?</strong></p>
            <p>La legitimaci&oacute;n para el tratamiento de sus datos personales viene dada por su consentimiento, otorgado en el momento de ponerse en contacto con nosotros, para la utilizaci&oacute;n de cookies anal&iacute;ticas, comportamentales y publicitarias; para las cookies de sesi&oacute;n o personalizaci&oacute;n, la legitimaci&oacute;n para el tratamiento viene dada por el inter&eacute;s leg&iacute;timo del prestador (responsable del tratamiento).</p>
            <p><strong>&iquest;A qui&eacute;n comunicamos sus datos?</strong></p>
            <p>No se ceder&aacute;n ni comunicar&aacute;n datos a terceros, salvo por obligaci&oacute;n legal o a los terceros indicados en el caso de uso de cookies gestionadas por &eacute;stos.&nbsp;</p>
            <p><strong>&iquest;Cu&aacute;l es el plazo de conservaci&oacute;n de sus datos?</strong></p>
            <p>El plazo de conservaci&oacute;n de los datos recogidos es el indicado en el cuadro sobre el uso de las cookies en esta web.</p>
            <p><strong>&iquest;Cu&aacute;les son sus derechos?</strong></p>
            <p>Los derechos que amparan al usuario son los de acceso, rectificaci&oacute;n, supresi&oacute;n, portabilidad de los mismos,&nbsp; retirada del consentimiento (sin que ello afecte a la licitud del tratamiento otorgado previamente a su retirada), oposici&oacute;n al tratamiento, limitaci&oacute;n (en determinadas circunstancias) y a presentar una reclamaci&oacute;n ante la autoridad de control (la Agencia Espa&ntilde;ola de protecci&oacute;n de datos, www.aepd.es).</p>
        </BasicPage>
    </Layout>
  )
}

export default PoliticaDeCookie


